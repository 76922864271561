$(document).ready(function(){
    $('#start-sick-animal').attr("href", "#")
    $('#start-healthy-animal').attr("href", "#")
    let clicked = false
    $('#start-sick-animal').on('click', function(e){
        if (!clicked)  window.location.href = "/start"
        clicked = true
        e.preventDefault()
    })

    $('#start-healthy-animal').on('click', function(e){
        if (!clicked)  window.location.href = "/start?stype=routine"
        clicked = true
        e.preventDefault()
    })

    $('.step-form').on('submit', function(){
        $('.button').attr("disabled", true)
    })
})